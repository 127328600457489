<template>
  <b-container class="max-w-900px px-0">
    <b-card class="card-custom" body-class="py-15" footer-class="text-center">
      <template v-slot:footer>
        <b-btn v-if="pagination.last_page > pagination.page" variant="primary" class="px-10" v-cs-loading="loading" @click="onLoadMore()">
          Load More
        </b-btn>
      </template>

      <KTTimelineList :items="notifications" />
    </b-card>
  </b-container>
</template>

<script>
import KTTimelineList from '@/components/generic/TimelineList';
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  name: 'notifications',
  components: {
    KTTimelineList,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      notifications: 'notifications/items',
      pagination: 'notifications/pagination',
    }),
  },
  methods: {
    ...mapActions({
      getNotifications: 'notifications/index',
    }),
    ...mapMutations({
      updatePaginationPage: 'notifications/UPDATE_PAGINATION_PAGE',
    }),
    async onLoadMore() {
      this.loading = true;
      this.updatePaginationPage({ page: this.pagination.page + 1, per: 25 });

      const params = { page: this.pagination.page, elasticsearch: true, infinite: 1 };

      await this.getNotifications(params);

      this.loading = false;
    },
  },
};
</script>
